import React from "react";
import { Routes, Route } from "react-router-dom";

import { Layout } from 'antd'

import "../App.less";

import Login from "../components/Login";

const {  Content, Footer } = Layout;

const Auth = () => {

    return (
        <Layout className="layout">
            <Content style={{ padding: '20px' }}>
                <div className="auth-box">
                    <Routes>
                        <Route exact path={'/'} element={<Login />} />
                    </Routes>
                </div>
            </Content>
           
        </Layout>
    );
};

export default Auth;