import { Form, message, Select, Button, Input, Card, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import SelectAjax from "../ui/SelectAjax";
import moment from "moment";
import certificationService from "../../services/certification.service";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import apiClient from "../../services/api";
import modificationService from "../../services/modification.service";

const { Option } = Select;

const ModificationBase = ({ mode = "create" }) => {
  const [options, setOptions] = useState([]);
  const [enabled, setEnabled] = useState([]);

  const [form] = Form.useForm();
  let { id } = useParams();

  const { data, isFetching } = useQuery(
    ["certifications", id],
    () => modificationService.getModification(id),
    { refetchOnWindowFocus: false, enabled: id > 0 }
  );

  const fetch = async () => {
    const res = await apiClient.get("/vehicle-features");
    setOptions(res.data);
  };

  const enableFeatures = () => {
    const { features } = data.modification;
    const enabledCopy = features?.map((a) => a.id);
    setEnabled(enabledCopy);
  };

  const isEnabled = (id) => {
    return enabled.findIndex((a) => a === id) > -1;
  };

  useEffect(() => {
    fetch();

    if (data) {
      if (data?.modification) {
        form.setFieldsValue(data.modification);

        const items = {};

        // eslint-disable-next-line no-unused-expressions
        data?.modification.features.map((a) => {
          items[a.id] = a.pivot.value;
        });

        form.setFieldsValue(items);

        enableFeatures();
      }
    }
  }, [data]);

  const finish = async (values) => {
    const res = await certificationService.updateModifications(id, values);

    if (!res.success) {
      message.error(res.message);
      return;
    }

    message.success(res.message);
  };

  const onSelect = (e, id) => {
    if (e.target?.innerHTML !== "") {
      let enabledCopy = [...enabled];
      const index = enabled.findIndex((a) => a === id);

      if (isEnabled(id)) {
        enabledCopy.splice(index, 1);
      } else {
        enabledCopy.push(id);
      }
      setEnabled(enabledCopy);

      //  const el = e.target.parentNode.querySelector("input");
      //  if( el) {
      //    el.focus();
      //  }
    }
  };

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`Modificaciones: ${data?.certification?.plate} / ${
          data?.certification?.inspection_number ?? ""
        }`}
        subTitle={moment().format("LLL")}
        extra={[
          mode === "edit" && (
            <Button key="modifications">Modificaciones</Button>
          ),
          <Button key="save" type="primary" onClick={() => form.submit()}>
            Guardar
          </Button>,
        ]}
      />

      <Form
        size="small"
        layout="vertical"
        form={form}
        name="control-hooks"
        initialValues={{ chassis_status: "NUEVO" }}
        onFinish={finish}
      >
        <Card loading={isFetching} className="cardex" title="General">
          <Form.Item
            name="type"
            label="Tipo de modificación"
            rules={[{ required: true }]}
          >
            <SelectAjax
              size="middle"
              style={{ maxWidth: 400 }}
              url={"/modification-types"}
            />
          </Form.Item>

          <Form.Item
            name="chassis_status"
            style={{ maxWidth: 400 }}
            label="Estado de chasis"
            rules={[{ required: true }]}
          >
            <Select size="middle">
              <Option value="NUEVO">NUEVO</Option>
              <Option value="USADO">USADO</Option>
            </Select>
          </Form.Item>
        </Card>

        <Card
          className="cardex"
          title="Cambios disponibles"
          style={{ marginTop: 20 }}
        >
          <div className="custom_form">
            {options.map((a, i) => {
              return (
                <Form.Item
                  key={a.id}
                  onClick={(e) => onSelect(e, a.id)}
                  className={isEnabled(a.id) ? "active" : ""}
                  name={a.id}
                  label={a.name}
                  rules={[{ required: false }]}
                >
                  <Input disabled={!isEnabled(a.id)} />
                </Form.Item>
              );
            })}
          </div>

          <Form.Item
            name="observation"
            label="Observaciones"
            rules={[{ required: false }]}
          >
            <Input.TextArea size="middle" />
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
};

export default ModificationBase;
