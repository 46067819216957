
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import certificationService from "../../services/certification.service";
import GLPCreate from "./GLPCreate";
import CardLoader from "../ui/CardLoader";
import CertificationCreate from "./CertificationCreate";
import GNVCreate from "./GNVCreate";
import TallerCreate from "./TallerCreate";


const CertificationEditor = ()=> {
  
    let { id } = useParams();
    
    const [categoryId, setCategoryId] = useState(null)

    const fetch = async () => {
           const res = await  certificationService.find(id)

           const {certification} = res 

         setCategoryId(certification.category_id);
    }

    useEffect(()=>{

       fetch();
    },[])

    if (!categoryId) return <CardLoader/>

  if (categoryId === 1) return <CertificationCreate back="/certifications" mode="edit"/>
    if( categoryId === 2) return <GLPCreate back="/certifications/glp" mode="edit"/>
  if (categoryId === 3) return <GNVCreate back="/certifications/gnv" mode="edit"/>
  if (categoryId === 4) return <TallerCreate back="/certifications/garage" mode="edit"/>

    return <div style={{ miHeight: '100vh' }}>No valid certification code: {categoryId}</div>
}

export default CertificationEditor;