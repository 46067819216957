
import apiClient from "./api";

const fetch = (page = 0) => {
    return apiClient.get( "/admin/garages?page=" + page).then(res => res.data);
};


const find = (id) => {
    return apiClient.get("/admin/garages/"+id).then(res => res.data);
};

const remove = (id) => {
    return apiClient.delete("/admin/garages/"+id).then(res => res.data);
};

 const create = (params) => {
    return apiClient.post("/admin/garages", params).then(res => res.data);
};


 const update = (id, params) => {
    return apiClient.patch("/admin/garages/"+id, params).then(res => res.data);
};

export default  {
    fetch, 
    find, 
    create, 
    update, 
    remove, 
}
