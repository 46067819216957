
import { Form, message, Button, Modal, Input, Card, Col, Row, PageHeader} from "antd"
import React, { useEffect } from "react"
import SelectAjax from "../ui/SelectAjax";
import moment from 'moment'
import certificationService from "../../services/certification.service";
import { useQuery } from "react-query";
import { useParams, useNavigate, Link} from "react-router-dom";
import {  ExclamationCircleOutlined } from '@ant-design/icons';
import apiClient from "../../services/api";
import PlateFetcher from "../ui/PlateFetcher";
import CardInfo from "./CardInfo";



const CertificationCreate = ({ mode = 'create', back}) => {
  
  const [form] = Form.useForm();
  let { id } = useParams();
  const navigate = useNavigate()

  const {
    data,
    isFetching,
  } = useQuery(['certifications', id], () => certificationService.find(id), { keepPreviousData: false, enabled: id > 0 })


  useEffect(()=> {

    if (data) {
      form.setFieldsValue(data)
    }

  }, [ data]);

  const finish =  async (e) => {

      try {

        let res;
        if( mode === 'create') {
          res = await certificationService.create(e);
        }else {
          res = await certificationService.update(id, e);
         
        }

        if (res.success) {
          message.success(res.message)
          if (mode === 'create') {
            navigate("/certifications/"+res.data.id+'/edit');
            return;
          }

          form.setFieldsValue(res.data)
        
          return;
          
        }
        
        message.error(res.message)

      } catch (error) {
        message.error(error.message);
      }

     
  }

  const removeModel = async () => {
    Modal.confirm({
      title: 'Confirmar borrado',
      icon: <ExclamationCircleOutlined />,
      content: 'Se borrará este registro y los registros relacionados.',
      okText: 'SI',
      cancelText: 'NO',
      onOk: async () => {
   
        const res = await certificationService.remove(id)
       

        if (!res.success) {
          message.error(res.message ?? 'Error al eliminar registro');
        }
        message.success(res.message ?? 'Borrado exitoso');
        navigate('/certifications');

      }
    });

  }

  const searchPlate = async (e) => {

    if( e.target.value.length === 6) {

    const res = await apiClient.get('/plate-search', {params: {plate: e.target.value}});
    
 
    if( res.data.success) {

      form.setFieldsValue({
        ...res.data.data,
        owner1: res.data.data.owners.join(' / '),
        serie: res.data.data.serial_number,
        chassis: res.data.data.vin,
      });
    }

    }


  }

    return <div>


      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={mode === 'create' ? "Nuevo ingreso" : data?.certification?.name}
        subTitle={data?.date ? moment(data?.date).format('LLL') : ''}
        extra={[
          mode === 'edit' && <Button onClick={removeModel}  danger key="cancel">Anular</Button>,
          mode === 'edit' && <Button key="modifications"><Link to={'/certifications/resolve/'+id }>Modificaciones</Link></Button>,
          <Button key="save" type="primary" onClick={()=>form.submit()}>
            Guardar
          </Button>,
        ]}
      />


      <Form size="small" form={form} name="control-hooks" initialValues={{ certification_type_id: 1, plate: 'B3G019', transport_type_id : 1}} onFinish={finish} 
      
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >


        <Card loading={isFetching} className="cardex" title={<div style={{ display: 'flex', justifyContent:'space-between' }}><div>Datos básicos</div> {mode ==='edit' && <div style={{fontWeight: 300}}>Operación: {data?.operation_code}</div>}</div>} >

          <Form.Item labelCol={{ span: 2 }} name="company_id" label="Empresa" rules={[{ required: true }]}>
            <SelectAjax size="middle" showSearch optionFilterProp="children" url={'/companies-list'} style={{ width: 250 }} />
          </Form.Item>
        
          <Form.Item labelCol={{ span: 2 }} name="certification_type_id" label="Tipo cert." rules={[{ required: true }]}>
            <SelectAjax size="middle" style={{maxWidth: 400}} url={'/certification-types'  } />
          </Form.Item>
         
          <Form.Item labelCol={{ span: 2 }} name="plate" label="Placa" rules={[{ message: 'Ingresa placa válida', required: true, pattern: /^[0-9a-zA-Z-]{6,50}$/ } ]}>
            <Input placeholder="0203DS" onChange={searchPlate} size="middle" maxLength={7} style={{ width: 250, textTransform: 'uppercase' }} />
          </Form.Item>

          <Form.Item labelCol={{ span: 2 }} name="dua" label="DUA" rules={[{ message: 'Ingresa dua válida', required: false, }]}>
            <Input placeholder="" size="middle" maxLength={7} style={{ width: 250, textTransform: 'uppercase' }} />
          </Form.Item>

          <Form.Item labelCol={{ span: 2}} name="transport_type_id" label="Transporte" rules={[{ required: true }]}>
            <SelectAjax size="middle" url={'/transport-types'} style={{ width: 250 }} />
          </Form.Item>


         

      </Card>

        <Card loading={isFetching} className="cardex" title="Datos del vehículo" style={{ marginTop: 20 }}>

          <Form.Item name="owner1" labelCol={{ span: 2 }} label="Propietarios" rules={[{ required: false }]}>
            <Input size="middle" />
          </Form.Item>


          <Row>


            <Col span={15}>

              <Row>

                <Col span={10}>

                  <Form.Item name="class" label="Cat./Clase" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="model" label="Modelo" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="brand" label="Marca" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="serie" label="Serie" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="chassis" label="Chasis" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="motor" label="Motor" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="color" label="Color" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>





                  <Form.Item name="bodywork" label="Carrocería" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>


                  <Form.Item name="fuel" label="Combustible" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="power" label="Potencia" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                </Col>

                <Col span={14}>

                  <Row>
                    <Col span={12}>
                      <Form.Item name="year_fabric" label="Año Fabric" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="year_model" label="Año Model" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>


                      <Form.Item name="axes_number" label="Ejes" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="wheels_number" label="Ruedas" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="seats_number" label="Asientos" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="engine_size" label="Cilindrada" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="longitude" label="Longitud" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                    </Col>

                    <Col span={12}>

                      <Form.Item name="height" label="Alto" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>


                      <Form.Item name="width" label="Ancho" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>



                      <Form.Item name="passenger_number" label="Pasajeros" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="cylinder_number" label="Cilindros" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="gross_weight" label="Peso Bruto" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="dry_weight" label="Peso seco" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="payload" label="Carga útil" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                   
                     
                    </Col>
                  </Row>
                </Col>

    
              </Row>



            </Col>

            <Col span={9}>
              {mode !== 'edit' && <PlateFetcher />}


            </Col>

          </Row>

        </Card>

      
</Form>


      <CardInfo certification={data} />
          

    </div>
}

export default CertificationCreate;