
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import certificationService from "../../services/certification.service";
import GLPAnual from "../Modification/GLPAnual";
import GLPInicial from "../Modification/GLPInicial";
import GNVInicial from "../Modification/GNVInicial";
import ModificationBase from "../Modification/ModificationBase";

import CardLoader from "../ui/CardLoader";
import TallerCreate from "./TallerCreate";

const components = {
    1: ModificationBase,
    16: GLPInicial,
    17: GLPAnual,
    18: TallerCreate,
    19: TallerCreate,
    20: GNVInicial,
    21: GNVInicial,
    22: TallerCreate,
    23: TallerCreate,
};


const CertificationModificator = ()=> {
  
    let { id } = useParams();
    
    const [type, setTypeId] = useState(null)

    const fetch = async () => {
           const res = await  certificationService.find(id)
        setTypeId(res.certification_type_id);
    }

    useEffect(()=>{
        fetch();
    },[])

    if (!type) return <CardLoader/>

    const TheShowComponent = components[type];

    if (typeof (TheShowComponent) === 'function') {
        return <TheShowComponent/>
    }

    return <div style={{ miHeight: '100vh' }}>No valid certification code: {type}</div>
}

export default CertificationModificator;