import React from "react";
import { useSanctum } from "react-sanctum";
const Profile = () => {
  const { user } = useSanctum();
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>{user?.username}</strong> Profile
        </h3>
      </header>

      <p>
        <strong>Id:</strong> {user?.id}
      </p>
      <p>
        <strong>Email:</strong> {user?.email}
      </p>
      <strong>Authorities:</strong>
      <ul>
        {user?.roles &&
          user.roles.map((role, index) => <li key={index}>{role.name}</li>)}
      </ul>
    </div>
  );
};

export default Profile;
