import React, { useState } from "react";
import { DocumentTypes } from "../../../Helpers/Constants";
import CustomTable from "../../ui/CustomTable";
import { EditOutlined } from '@ant-design/icons';
import CustomerForm from "./CustomerForm";

const Customers = () => {

    const [currentId, setCurrentId] = useState(null);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        }, {
            title: 'Name',
            dataIndex: 'fullname',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Documento',
            dataIndex: 'document_number',
            key: 'document_number',
            render: (value, record) => DocumentTypes[record.document_type] + ": " + record.document_number,
            sorter: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },

        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'edit',
            render: (id) => <a onClick={() => setCurrentId(id)}><EditOutlined /></a>,
        },


    ];

    return (
        <div className="container">

            <CustomTable
                createForm={CustomerForm}
                editForm={CustomerForm}
                edit={currentId}
                title="Clientes"
                columns={columns}
                onCancel={() => setCurrentId(null)}
                url={'/admin/customers'} />

        </div>
    );
};

export default Customers;
