import React, { useState, useEffect } from "react";
import { useSanctum } from "react-sanctum";

import UserService from "../services/user.service";
import BoardAdmin from "./BoardAdmin";

const Home = () => {


     const { user } = useSanctum();

    useEffect(() => {
    

       if( user && user.roles.find(a => a.name !== 'superadmin')  ) {
        
        window.location.href = '/certifications';
        return;
       }


    }, [user]);

    return (
        <div className="container">
            <header className="jumbotron">
        
                <BoardAdmin/>
            </header>
        </div>
    );
};

export default Home;
