import axios from "axios";
import apiClient from "./api";
import authHeader from "./auth-header";



const fetch = (page = 0) => {
    return apiClient.get("/admin/users?page=" + page).then(res => res.data);
};


const find = (id) => {
    return apiClient.get("/admin/users/" + id).then(res => res.data);
};

const remove = (id) => {
    return apiClient.delete("/admin/users/" + id).then(res => res.data);
};

const create = (params) => {
    return apiClient.post("/admin/users", params).then(res => res.data);
};


const update = (id, params) => {
    return apiClient.patch("/admin/users/" + id, params).then(res => res.data);
};

export default {
    fetch,
    find,
    create,
    update,
    remove,
}
