/* eslint-disable jsx-a11y/anchor-is-valid */
import { Result , Button} from "antd";
import React, { useState } from "react";
import { useSanctum } from "react-sanctum";


const Fallback = () => {
     const {   user } = useSanctum();
      const isSuperadmin = user.roles.find(a => a.name === 'superadmin');

return (
    <div className="container">

        <Result
    status="403"
    title="403"
    subTitle="Lo sentimos, no está autorizado para acceder a esta página."
    extra={<Button href={isSuperadmin ? '/' : '/certifications'} type="primary">Ir al Inicio</Button>}
  />

    </div>
);
};

export default Fallback;
