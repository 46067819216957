import React, { useEffect, useState } from "react";
import { useSanctum } from "react-sanctum";
import Main from "./layouts/Main";
import Auth from "./layouts/Auth";
import "./App.less";
import FullLoader from "./Helpers/FullLoader";
import { Navigate, useLocation } from "react-router-dom";
import moment from "moment";
import es from "moment/locale/es";

moment().locale("es", es);

const App = () => {
  const location = useLocation();
  const { authenticated, checkAuthentication, user } = useSanctum();
  const [networkStatus, setNetworkStatus] = useState("active");

  useEffect(() => {
    setInterval(() => {
      checkAuthentication().catch((err) => {
        if (err.toJSON().message === "Network Error") {
          setNetworkStatus("failed");
        }
      });
    }, 1000 * 20);
  }, [checkAuthentication]);

  if (authenticated === null && networkStatus === "failed") {
    return (
      <FullLoader
        message={"Error de conexión con el servidor, verifique internet!"}
      />
    );
  }

  if (authenticated === null) {
    return <FullLoader />;
  }

  if (authenticated === false && location.pathname !== "/") {
    return <Navigate to={"/"} />;
  }

  return <div className="wrapper">{authenticated ? <Main /> : <Auth />}</div>;
};

export default App;
