import apiClient from "../services/api";
import { Modal } from "antd";
import React from 'react';

const download = async (show, fileName, url, mime) => {
  const res = await apiClient
    .get(url, { responseType: "arraybuffer" })
    .then((res) => res.data);

  var file = new Blob([res], { type: mime });
  var fileURL = URL.createObjectURL(file);

  if (show) {
    Modal.info({
      closable: true,
      icon: null,
      content: (
        <iframe
          src={fileURL}
          style={{ width: "100%", height: "70vh", border: "none" }}
        />
      ),
      width: "80%",
      okText: "Cerrar",
      onOk() {
        URL.revokeObjectURL(fileURL);
      },
    });
    return;
  }

  var a = document.createElement("a");
  document.body.appendChild(a);

  a.href = fileURL;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(fileURL);
};

export default download;

export const downloadPDF = async (show, fileName, url) => {
  
  download(show, fileName, url, "application/pdf")
};

export const downloadFromData = async (data, mime, fileName) => {

    var file = new Blob([data], { type: mime });
    var fileURL = URL.createObjectURL(file);

      var a = document.createElement("a");
    document.body.appendChild(a);

    a.href = fileURL;
    a.download = fileName;
    a.click();

};