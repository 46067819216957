/* eslint-disable jsx-a11y/anchor-is-valid */

import { Button, Divider, Drawer, message, DatePicker , Flex} from "antd";
import React, { useEffect, useState } from "react";
import { EditOutlined, FilePdfOutlined } from "@ant-design/icons";
import CustomTable from "../ui/CustomTable";
import { Link, useParams } from "react-router-dom";
import { downloadFromData, downloadPDF } from "../../Helpers/Functions";
import Uploader from "../ui/Uploader";
import moment from "moment";
import certificationService from "../../services/certification.service";

const { RangePicker } = DatePicker;

const Certification = () => {
  let { slug } = useParams();
  const [selectedRange, setSelectedRange] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [certUploadId, setCertUpload] = useState(null);

  const [visible, setVisible] = useState(false);
  const showDrawer = (id) => {
    setCertUpload(id);
    setVisible(true);
  };
  const onCloseDrawer = () => {
    setCertUpload(null);
    setVisible(false);
  };

  const columns = [
    {
      title: "Informe",
      dataIndex: "inspection_number",
      key: "inspection_number",
      sorter: true,
    },
    {
      title: "Placa",
      dataIndex: "plate",
      key: "plate",
      sorter: true,
    },
    {
      title: "Certificado",
      dataIndex: "certificate_number",
      key: "certificate_number",
      sorter: true,
      render: (value) => value ?? "-",
    },
    {
      title: "Propietario",
      dataIndex: "owner1",
      key: "owner1",
      render: (value, record) => (
        <Link to={"/certifications/" + record.id + "/edit"}>{value}</Link>
      ),
    },

    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      render: (value) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
    },

    {
      title: "Cat",
      dataIndex: "class",
      key: "class",
      render: (value) => (value !== "" ? value : "-"),
    },

    {
      title: "Tipo",
      dataIndex: "certification_type_id",
      key: "certification_type_id",
      render: (value, record) => record.certification.name,
    },

    {
      title: "Cert",
      dataIndex: "pdf_path",
      key: "pdf_path",
      render: (value, record) =>
        record.attachments.length > 0 ? (
          <a
            target={"blank"}
            style={{ color: "red" }}
            href={record.attachments[0].url}
          >
            <FilePdfOutlined />
          </a>
        ) : (
          "-"
        ),
    },

    {
      title: "PDF",
      dataIndex: "pdf_path",
      key: "pdf_path",
      render: (value, record) =>
        value ? (
          <a onClick={() => downloadPDF(true, record.id, record.pdf_url)}>
            <FilePdfOutlined />
          </a>
        ) : (
          "-"
        ),
    },

    {
      title: "Documentos",
      dataIndex: "id",
      key: "documents",
      render: (value) => (
        <Button onClick={() => showDrawer(value)} size="small">
          Ver o subir
        </Button>
      ),
    },

    {
      title: "Editar",
      dataIndex: "id",
      width: 90,
      key: "edit",
      render: (id) => (
        <Link to={"/certifications/" + id + "/edit"}>
          <EditOutlined /> Editar
        </Link>
      ),
    },
  ];

  useEffect(() => {
    document.title = "Listado de certificaciones";
  }, []);

  const exportExcel = async (filters) => {
   
    const filterMerged = {certcategory: slug, ... filters}
    try {
     const data = await certificationService.exportExcel(filterMerged);

     downloadFromData(data,'application/vnd.ms-excel', 'certifications.xlsx' ) 
    } catch (error) {
      message.error(error.message)
    }

  }

  const handlePresetClick = (setFilters, range) => {
    setSelectedRange(range)
    setFilters({
      from: range[0].format('YYYY-MM-DD'),
      to: range[1].format('YYYY-MM-DD'),
    });
  };

  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const startOfWeek = moment().startOf('week');
  const endOfWeek = moment().endOf('week');
  const startOfMonth = moment().startOf('month');
  const endOfMonth = moment().endOf('month');
  const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
  const endOfLastMonth = moment().subtract(1, 'month').endOf('month');
  const startOfYear = moment().startOf('year');
  const endOfYear = moment().endOf('year');

  return (
    <div className="container">
      <CustomTable
        edit={currentId}
        title="Certificaciones"
        columns={columns}
        onCancel={() => setCurrentId(null)}
        url={"/certifications?certcategory=" + (slug ?? "")}
      >{({filters, filterText,  setFilters})=> (
        <div className="flex">

<div>
              <RangePicker
                value={selectedRange}
                renderExtraFooter={() => (
                  <div style={{ display: 'flex', gap: 10, justifyContent: 'space-between' }}>
                    <Button type="link" onClick={() => handlePresetClick(setFilters, [today, today])}>Hoy</Button>
                    <Button type="link" onClick={() => handlePresetClick(setFilters, [yesterday, yesterday])}>Ayer</Button>
                    <Button type="link" onClick={() => handlePresetClick(setFilters, [startOfWeek, endOfWeek])}>La semana pasada</Button>
                    <Button type="link" nClick={() => handlePresetClick(setFilters, [startOfMonth, endOfMonth])}>Este mes</Button>
                    <Button type="link" onClick={() => handlePresetClick(setFilters, [startOfLastMonth, endOfLastMonth])}>El mes pasado</Button>
                    {/* <Button type="link" onClick={() => handlePresetClick(setFilters, [startOfYear, endOfYear])}>Este año</Button> */}
                  </div>
                )}
                onChange={(values) => {
                  if (values) {
                    setFilters({
                      from: values[0].format('YYYY-MM-DD'),
                      to: values[1].format('YYYY-MM-DD'),
                    });
                  }
                }}
              />
            </div>

        <Button style={{marginRight: 5}} onClick={()=>exportExcel({...filters, filterText })}>Exportar</Button>
          {slug && (
            <Button type="primary">
              <Link to={"/certifications/" + slug + "/new"}>
                + Nuevo ingreso{" "}
              </Link>
          </Button>
        )}

        </div>
      )}
        
      </CustomTable>

      <Drawer
        title="Documentos"
        width={400}
        placement="right"
        onClose={onCloseDrawer}
        visible={visible}
      >
        <Divider>Certificado</Divider>
        <div className="uploader">
          <Uploader type={"certificate"} certificationId={certUploadId} />
        </div>

        <Divider>Documentos</Divider>
        <div className="uploader">
          <Uploader type={"document"} certificationId={certUploadId} />
        </div>
        <Divider>Fotos</Divider>
        <div className="uploader">
          <Uploader type={"photo"} certificationId={certUploadId} />
        </div>
      </Drawer>
    </div>
  );
};

export default Certification;
