import { Col, Row, Statistic } from "antd";
import React, { useState, useEffect } from "react";

import CertificatesLine from "./Dashboard/Charts/CertificatesLine";

const BoardAdmin = () => {
  const [content, setContent] = useState("");

  useEffect(() => {}, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Certificaciones</h3>

        <CertificatesLine />

        <br />
        <br />
        <h3>Estadísticas mes</h3>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={6}>
            <Statistic title="Certificaciones" value={92893} />
          </Col>

          <Col span={6}>
            <Statistic title="Procesos pendientes" value={52893} />
          </Col>

          <Col span={6}>
            <Statistic title="Procesos anulados" value={1897} />
          </Col>

          <Col span={6}>
            <Statistic title="Facturación (PEN)" value={142893} precision={2} />
          </Col>
        </Row>
      </header>
    </div>
  );
};

export default BoardAdmin;
