import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";

import { Layout, Menu, message, Dropdown } from "antd";

import {
  DesktopOutlined,
  ContainerOutlined,
  BgColorsOutlined,
  ExperimentOutlined,
  DollarCircleOutlined,
  SettingOutlined,
  BuildOutlined,
  SolutionOutlined,
  ApiOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

import { QueryClient, QueryClientProvider } from "react-query";

import "../App.less";

import { useSanctum } from "react-sanctum";
import Home from "../components/Home";
import Register from "../components/Register";
import Profile from "../components/Profile";
import BoardUser from "../components/BoardUser";
import BoardModerator from "../components/BoardModerator";
import Companies from "../components/Admin/Companies/Companies";
import Customers from "../components/Admin/Customers/Customers";
import Garages from "../components/Admin/Garages/Garages";
import Users from "../components/Admin/Users/Users";
import Certification from "../components/Certification/Certification";
import CertificationCreate from "../components/Certification/CertificationCreate";
import GLPCreate from "../components/Certification/GLPCreate";
import CertificationEditor from "../components/Certification/CertificationEditor";
import CertificationModificator from "../components/Certification/CertificationModificator";
import GNVCreate from "../components/Certification/GNVCreate";
import TallerCreate from "../components/Certification/TallerCreate";
import CompanyPrice from "../components/Admin/Companies/CompanyPrice";
import Fallback from "../components/Errors/Fallback";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const Main = ({ home }) => {
  const { signOut, user } = useSanctum();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  const rootKeys = ["certifications", "management"];
  const defaultKeys = user?.roles.find((a) => a.name === "superadmin")
    ? ["home"]
    : ["certifications", "certifications.all"];

  const logOut = async () => {
    try {
      await signOut();
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    if (!collapsed) {
      setOpenKeys(defaultKeys);
    }
  }, [collapsed]);

  // Open only one submenu at a time
  const onOpenChange = (items) => {
    const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(items);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : defaultKeys);
    }
  };

  const isSuperadmin = user.roles.find((a) => a.name === "superadmin");

  const menuUser = (
    <Menu>
      <Menu.Item>
        <Link to={"/"}>Ir al Home</Link>
      </Menu.Item>
      <Menu.Item onClick={signOut}>Cerrar Sesión</Menu.Item>
    </Menu>
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">
            <a href={isSuperadmin ? "/" : "/certifications"}>
              {" "}
              <img alt="logo" src="/img/logo.png" />
            </a>
          </div>
          <Menu
            mode="inline"
            theme="dark"
            openKeys={openKeys}
            defaultSelectedKeys={defaultKeys}
            onOpenChange={onOpenChange}
          >
            {isSuperadmin && (
              <Menu.Item key={"home"} icon={<DesktopOutlined />}>
                <Link to="/">Dashboard</Link>
              </Menu.Item>
            )}
            <SubMenu
              key="certifications"
              icon={<SolutionOutlined />}
              title={"Certificaciones"}
            >
              <Menu.Item icon={<ContainerOutlined />} key="certifications.all">
                <Link to="/certifications">Ver todos</Link>
              </Menu.Item>

              <SubMenu
                disabled
                key="1"
                icon={<BgColorsOutlined />}
                title={"Conformidad"}
              >
                <Menu.Item key="1.index">
                  <Link to="/certifications/modification">Listado</Link>
                </Menu.Item>

                <Menu.Item key="1.new">
                  <Link to="/certifications/modification/new">
                    Nuevo ingreso
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="2" icon={<ExperimentOutlined />} title={"GLP"}>
                <Menu.Item key="2.index">
                  <Link to="/certifications/glp">Listado</Link>
                </Menu.Item>

                <Menu.Item key="2.new">
                  <Link to="/certifications/glp/new">Nuevo ingreso</Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="3" icon={<ApiOutlined />} title={"GNV"}>
                <Menu.Item key="3.index">
                  <Link to="/certifications/gnv">Listado</Link>
                </Menu.Item>

                <Menu.Item key="3.new">
                  <Link to="/certifications/gnv/new">Nuevo ingreso</Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="4" icon={<BuildOutlined />} title={"Talleres"}>
                <Menu.Item key="4.index">
                  <Link to="/certifications/garage">Listado</Link>
                </Menu.Item>

                <Menu.Item key="4.new">
                  <Link to="/certifications/garage/new">Nuevo ingreso</Link>
                </Menu.Item>
              </SubMenu>
            </SubMenu>

            {isSuperadmin && (
              <Menu.Item
                disabled
                key={"billing"}
                icon={<DollarCircleOutlined />}
              >
                Facturación
              </Menu.Item>
            )}

            {isSuperadmin && (
              <SubMenu
                key="management"
                icon={<SettingOutlined />}
                title={"Administración"}
              >
                <Menu.Item key="companies">
                  <Link to="/admin/companies">Empresas</Link>
                </Menu.Item>
                <Menu.Item key="customers">
                  <Link to="/admin/customers">Clientes</Link>
                </Menu.Item>
                <Menu.Item key="garages">
                  <Link to="/admin/garages">Talleres</Link>
                </Menu.Item>
                <Menu.Item key="users">
                  <Link to="/admin/users">Usuarios</Link>
                </Menu.Item>
              </SubMenu>
            )}
          </Menu>
        </Sider>

        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
              }
            )}

            <Dropdown
              arrow={false}
              overlay={menuUser}
              trigger={["click"]}
              placement="bottomLeft"
            >
              <div>
                <div className="loginav">
                  <div>{user.name}</div>
                  <img
                    className="avatar"
                    alt="avatar"
                    src={
                      "https://avatars.dicebear.com/api/male/" +
                      user.name +
                      ".svg?mood[]=happy"
                    }
                  />
                </div>
              </div>
            </Dropdown>
          </Header>

          <Content style={{ padding: "0 50px" }}>
            <div className="site-layout-content">
              <Routes>
                <Route exact path={"/"} element={<Home />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route path="/user" element={<BoardUser />} />
                <Route path="/mod" element={<BoardModerator />} />

                <Route path="/fallback" element={<Fallback />} />

                <Route
                  path="/admin/companies/:id/prices"
                  element={<CompanyPrice />}
                />

                {isSuperadmin && (
                  <Route path="/admin/customers" element={<Customers />} />
                )}
                {isSuperadmin && (
                  <Route path="/admin/garages" element={<Garages />} />
                )}
                {isSuperadmin && (
                  <Route path="/admin/users" element={<Users />} />
                )}
                {isSuperadmin && (
                  <Route path="/admin/companies" element={<Companies />} />
                )}

                <Route
                  path="/certifications/:slug"
                  element={<Certification slug="glp" />}
                />
                <Route path="/certifications" element={<Certification />} />

                <Route
                  path="/certifications/modification/new"
                  element={<CertificationCreate />}
                />
                <Route path="/certifications/glp/new" element={<GLPCreate />} />
                <Route path="/certifications/gnv/new" element={<GNVCreate />} />
                <Route
                  path="/certifications/garage/new"
                  element={<TallerCreate />}
                />

                <Route
                  path="/certifications/:id/edit"
                  element={<CertificationEditor mode="edit" />}
                />
                <Route
                  path="/certifications/resolve/:id"
                  element={<CertificationModificator />}
                />

                <Route
                  path="/*"
                  element={
                    <Navigate
                      to={"/fallback?from=" + window.location.pathname}
                      replace
                    />
                  }
                />
              </Routes>
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>© 2022 Imontgall SAC</Footer>
        </Layout>
      </Layout>
    </QueryClientProvider>
  );
};

export default Main;
