/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useSanctum } from "react-sanctum";
import { Button, Input, Form, Checkbox, message, Spin } from "antd";
const Login = (props) => {
  const [loading, setLoading] = useState(false);

  const { signIn } = useSanctum();

  const onFinish = (values) => {
    const remember = true;
    setLoading(true);

    signIn(values.username, values.password, remember)
      .then((a) => {
        setLoading(false);
        if (a.user) {
          message.success("¡Login success!");
        } else {
          message.error("¡Email o contraseña incorrecta!");
        }
      })
      .catch((err) => {
        setLoading(false);

        message.error("Error de conexión con el servidor");
      });
  };

  return (
    <div className="card">
      <img
        src="/img/logo.png"
        style={{ margin: "10px auto", maxWidth: 100, display: "block" }}
      />
      <Spin spinning={loading}>
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            remember: true,
            username:
              process.env.NODE_ENV === "development"
                ? "gerencia@certificadoraimg.com"
                : undefined,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Usuario"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Recuerdame</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Acceder
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default Login;
