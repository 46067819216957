export const DOCUMENT_TYPE_SIN_RUC = '0';
export const DOCUMENT_TYPE_DNI = '1';
export const DOCUMENT_TYPE_CE = '4';
export const DOCUMENT_TYPE_RUC = '6';
export const DOCUMENT_TYPE_PASAPORTE = '7';
export const DOCUMENT_TYPE_CDI = 'A';
export const DOCUMENT_TYPE_DOC_PAIS_RESIDENCIA = 'B';
export const DOCUMENT_TYPE_TIN = 'C';
export const DOCUMENT_TYPE_IN = 'D';

export const INVOICE_TYPE_FACTURA = '01';
export const INVOICE_TYPE_BOLETA_VENTA = '03';
export const INVOICE_TYPE_NOTA_CREDITO = '07';
export const INVOICE_TYPE_NOTA_DEBITO = '08';
export const INVOICE_TYPE_GUIA_REMISION_REMITENTE = '09';
export const INVOICE_TYPE_TICKET = '12';
export const INVOICE_TYPE_GUIA_REMISION_TRANSPORTISTA = '31';


export const DocumentTypes =  {
    0: 'SIN RUC',
    1: 'DNI',
    4: 'CE',
    6: 'RUC',
    7: '7',
}