/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Input, PageHeader, Button, Table, message } from "antd";
import companyService from "../../../services/company.service";



const CompanyPrice = () => {

    const { id } = useParams();

    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetch = async () => {
        const res = await companyService.prices(id);
        setPrices(res);
    }

    const saveData = async () => {
        setLoading(true);

        const newPrices = prices.map(a => ({
            id: a.id,
            price: a.pivot.price,
        }))
        try {
            const res = await companyService.updatePrices(id, {prices: newPrices});

            if (res.success) {
                message.success(res.message)

            } else {
                message.warning(res.messsa ?? 'Error al guardar');
            }


        } catch (error) {

            message.error(error.message)
        }

        setLoading(false);

    }

    const handleChange = (e, record, pos) => {

        const a = [...prices];
        a[pos].pivot.price = e.target.value;

        setPrices(a);
    }

    useEffect(() => {
        fetch();
    }, [])


    const columns = [
        {
            title: 'Certificado',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Precio',
            dataIndex: 'pivot.price',
            render: (a, record, pos) => <Input
                value={record.pivot.price}
                onChange={(e) => handleChange(e, record, pos)}
                // onBlur={handleBlur}
                placeholder="Input a number"
                maxLength={25}
            />,
            key: 'price',
        },
    ];

    return <div>
        <PageHeader

            extra={[

                <Button loading={loading} key="save" type="primary" onClick={() => saveData()}>
                    Guardar
                </Button>,
            ]}

            title={'Configuración de precios'} />


        <Table rowKey={(record) => record.id} dataSource={prices} pagination={false} columns={columns} />

    </div>
}

export default CompanyPrice;