import React from "react";
import { Card } from "antd"

import moment from 'moment'

const CardInfo = ({certification})=> {
    return <Card className="cardex" title="Información" style={{ marginTop: 20 }}>
        Creado por: <strong>{certification?.creator?.name}</strong> <br/>
        Actualizado por: <strong>{certification?.modificator?.name}</strong> <br />
        Ultima actualización: <strong>{certification?.created_at ? moment(certification.created_at).format('LLL')  : '-'}</strong><br />
    </Card>
}

export default CardInfo;