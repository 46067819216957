import {
  Form,
  message,
  Button,
  Modal,
  Input,
  PageHeader,
  DatePicker,
  Divider,
  Spin,
} from "antd";
import React, { useState, useCallback, useEffect } from "react";
import SelectAjax from "../ui/SelectAjax";
import moment from "moment";
import certificationService from "../../services/certification.service";
import { useQuery } from "react-query";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { downloadPDF } from "../../Helpers/Functions";

import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import apiClient from "../../services/api";
import CardInfo from "./CardInfo";

const TallerCreate = ({ mode = "create", back }) => {
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [query] = useSearchParams();
  const [form] = Form.useForm();

  let { id } = useParams();

  const navigate = useNavigate();

  const { data, isFetching, refetch } = useQuery(
    ["certifications", id],
    () => certificationService.find(id),
    { keepPreviousData: false, enabled: id > 0, refetchOnWindowFocus: false }
  );

  const inyectForm = useCallback(() => {
    const formParams = {
      ...data,
      date: moment(data.date),
    };
    form.setFieldsValue(formParams);
  });

  useEffect(() => {
    if (data) {
      inyectForm();
    }

    if (data && query.get("id")) {
      form.setFieldsValue({ certification_type_id: query.get("id") });
    }
  }, [data]);

  const searchDniRuc = async (e) => {
    const text = e.target.value.replace(/[^0-9+]/g, "");

    let type = null;

    if (text.length === 8) {
      type = "dni";
    }

    if (text.length === 11) {
      type = "ruc";
    }

    if (!type) {
      return;
    }

    setSearchLoading(true);
    try {
      const res = await apiClient.get("/dni-ruc-search", {
        params: { type, document: text },
      });

      if (type === "dni") {
        form.setFieldsValue({
          garage_name: res.data.fullname,
        });
      } else {
        const rep = res.data.legal_representatives[0];

        form.setFieldsValue({
          garage_name: res.data.name,
          garage_address: res.data.address,
          garage_city: res.data.district,
          garage_legal_representant: rep?.fullname ,
        });
      }
    } catch (error) {}

    setSearchLoading(false);
  };

  const finish = async (e) => {
    try {
      let res;
      if (mode === "create") {
        res = await certificationService.create(e);
      } else {
        res = await certificationService.update(id, e);
      }

      if (res.success) {
        message.success(res.message);
        if (mode === "create") {
          navigate("/certifications/" + res.data.id + "/edit");
          return;
        }

        inyectForm();

        return;
      }

      message.error(res.message);
    } catch (error) {
      message.error(error.message);
    }
  };

  const removeModel = async () => {
    Modal.confirm({
      title: "Confirmar borrado",
      icon: <ExclamationCircleOutlined />,
      content: "Se borrará este registro y los registros relacionados.",
      okText: "SI",
      cancelText: "NO",
      onOk: async () => {
        const res = await certificationService.remove(id);

        if (!res.success) {
          message.error(res.message ?? "Error al eliminar registro");
        }
        message.success(res.message ?? "Borrado exitoso");
        navigate("/certifications");
      },
    });
  };

  const certificateNow = async () => {
    setTimeout(async () => {
      form
        .validateFields()
        .then(async (values) => {
          setLoading(true);

          const res = await certificationService.certificate(id);

          setLoading(false);

          if (!res.success) {
            message.error(res.message);
            return;
          }

          refetch();

          message.success(res.message);
        })
        .catch((err) => {
          setLoading(false);
          message.warn(err?.message || "¡Por favor completa los campos!");
        });
    }, 150);
  };

  const insNumber = `${data?.certification?.plate}-${data?.certification?.certificate_number}`

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={mode === "create" ? "Nuevo ingreso" : data?.certification.name}
        subTitle={data?.date ? moment(data?.date).format("LL") : ""}
        extra={[
          data?.status === "finished" && (
            <Button
              icon={<FilePdfOutlined />}
              key="show"
              onClick={() => downloadPDF(true, insNumber, data?.pdf_url)}
              loading={loading}
            >
              Ver
            </Button>
          ),

          data?.status === "finished" && (
            <Button
              icon={<DownloadOutlined />}
              key="download"
              onClick={() => downloadPDF(false, insNumber, data?.pdf_url)}
              loading={loading}
            >
              Descargar
            </Button>
          ),

          mode === "edit" && (
            <Button onClick={removeModel} danger key="cancel">
              Anular
            </Button>
          ),
          mode === "edit" && (
            <Button key="modifications" onClick={certificateNow}>
              Certificar
            </Button>
          ),
          <Button key="save" type="primary" onClick={() => form.submit()}>
            Guardar
          </Button>,
        ]}
      />

      <Divider />

      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        form={form}
        name="control-hooks"
        initialValues={{
          certification_type_id: 18,
          plate: "B3G019",
          transport_type_id: 1,
        }}
        onFinish={finish}
      >
        <Form.Item
          initialValue={moment()}
          name="date"
          label="Fecha"
          rules={[{ required: true, message: "Ingresa una fecha" }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="company_id"
          label="Empresa"
          rules={[{ required: true }]}
        >
          <SelectAjax
            size="middle"
            showSearch
            optionFilterProp="children"
            url={"/companies-list"}
            style={{ width: 250 }}
          />
        </Form.Item>

        <Form.Item
          name="certification_type_id"
          label="Tipo de cert."
          rules={[{ required: false }]}
        >
          <SelectAjax
            size="middle"
            style={{ maxWidth: 400 }}
            url={"/certification-types?category_id=4"}
          />
        </Form.Item>

        <Form.Item
          label="RUC O DNI"
          name={"garage_document_number"}
          rules={[
            {
              required: true,
              message: "Ingresa un documento válido",
              pattern: /^[0-9]{3,30}$/,
            },
          ]}
        >
          <Input
            suffix={<Spin size="small" spinning={searchLoading}></Spin>}
            onKeyUp={searchDniRuc}
          />
        </Form.Item>

        <Form.Item
          name="garage_name"
          label="Nombre del taller"
          rules={[
            {
              required: true,
              message: "Nombre es obligatorio",
              type: "string",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="garage_phone"
          label="Teléfono"
          rules={[
            {
              required: true,
              message: "Telefono es obligatorio",
              type: "string",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="garage_city"
          label="Ciudad"
          rules={[
            {
              required: true,
              message: "Ciudad es obligatorio",
              type: "string",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="garage_address"
          label="Dirección"
          rules={[
            {
              required: true,
              message: "La dirección es obligatoria",
              type: "string",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="garage_authorization_number"
          label="N° de autorización"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="garage_legal_representant"
          label="Rep. Legal"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="observation"
          label="Observaciones"
          rules={[{ required: false }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="certificate_number"
          label="Num. Certificado"
          rules={[{ required: true, message: "El certificado es obligatorio" }]}
        >
          <Input />
        </Form.Item>
      </Form>

      <CardInfo certification={data} />
    </div>
  );
};

export default TallerCreate;
