/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Spin } from "antd";


const PlateFetcher = ({data, loading})=> {

    const [vehicle, setVehicle]  = useState(null);


    useEffect(() => {

        setVehicle(data);

    }, [data])
    
    if( !loading && data && !data.url) {
        return <div className="component_plate_fetcher">No hay datos</div>
    }

    if( !loading && !data) {
        return <div className="component_plate_fetcher">Ingresa la placa para visualizar tarjeta.</div>
    }

    return <div className="component_plate_fetcher">
        {loading ? <Spin /> : <a href={vehicle?.url} target="_blank"><img src={vehicle?.url}/></a>}
    </div>
}

export default PlateFetcher;