
import { Form, message, Button, Modal, Input, Card, Col, Row, PageHeader, Alert} from "antd"
import React, { useEffect, useState } from "react"
import SelectAjax from "../ui/SelectAjax";
import moment from 'moment'
import certificationService from "../../services/certification.service";
import { useQuery } from "react-query";
import { useParams, useNavigate, Link, useSearchParams} from "react-router-dom";
import {  ExclamationCircleOutlined } from '@ant-design/icons';
import apiClient from "../../services/api";
import PlateFetcher from "../ui/PlateFetcher";
import Search from "antd/lib/input/Search";
import CardInfo from "./CardInfo";



const GNVCreate = ({ mode = 'create', back}) => {

  const [query] = useSearchParams();
  const [form] = Form.useForm();


  const [plateLoading, setPlateLoading] = useState(false);
  const [duaLoading, setDuaLoading] = useState(false);

  let { id } = useParams();

  const navigate = useNavigate()

  const {
    data,
    isFetching,
  } = useQuery(['certifications', id], () => certificationService.find(id), { keepPreviousData: false, enabled: id > 0 , refetchOnWindowFocus : false})


  useEffect(()=> {

    if (data) {
      form.setFieldsValue(data)
      document.title = `#${data?.id} / ${data?.owner1} / Editar`
    }else {
      document.title = `Crear GNV`
    }

    if (data && query.get('id')) {
       form.setFieldsValue({'certification_type_id': query.get('id')})
    }

  }, [data, form, query]);

  const finish =  async (e) => {

      try {

        let res;
        if( mode === 'create') {
          res = await certificationService.create(e);
        }else {
          res = await certificationService.update(id, e);
         
        }

        if (res.success) {
          message.success(res.message)
          if (mode === 'create') {
            navigate("/certifications/resolve/"+res.data.id);
            return;
          }

          form.setFieldsValue(res.data)
        
          return;
          
        }
        
        message.error(res.message)

      } catch (error) {
        message.error(error.message);
      }

     
  }

  const removeModel = async () => {
    Modal.confirm({
      title: 'Confirmar borrado',
      icon: <ExclamationCircleOutlined />,
      content: 'Se borrará este registro y los registros relacionados.',
      okText: 'SI',
      cancelText: 'NO',
      onOk: async () => {
   
        const res = await certificationService.remove(id)
       

        if (!res.success) {
          message.error(res.message ?? 'Error al eliminar registro');
        }
        message.success(res.message ?? 'Borrado exitoso');
        navigate('/certifications');

      }
    });

  }

  const searchDua = async (dua) => {
    setDuaLoading(true);
    try {
      const res = await apiClient.get("/dua-search", { params: { dua } });
      form.setFieldsValue(res.data );
    } catch (error) {
      message.warn('No se pudo obtener info de DUA, intenta mas tarde.')
    }
    setDuaLoading(false)
  }

  const searchPlate = async (e) => {

    const plate = e;

    if (plate.length === 6) {

      setPlateLoading(true);

      const res = await apiClient.get('/mtc-search', { params: { plate: plate } });

      if (res.data.Marca?.length > 2) {

        form.setFieldsValue({
          // owner1: '',
          class: res.data.Categoria,
          model: res.data.Modelo,
          brand: res.data.Marca,
          // serie: '',
          chassis: res.data.VINSerCha,
          motor: res.data.NumeroMotor,
          color: res.data.Color,
          bodywork: res.data.Carroceria,
          fuel: res.data.Combustible,
          'year_fabric': res.data.AñoFab,
          'year_model': '',
          'traction': '',
          'axes_number': res.data.NumeroEjes,
          'wheels_number': res.data.NumeroRuedas,
          'seats_number': res.data.NumeroAsientos,
          'engine_size': '',//cilindrada
          'longitude': res.data.Largo,
          'height': res.data.Alto,
          'width': res.data.Ancho,
          'passenger_number': res.data.NumeroPasajeros,
          'cylinder_number': '',
          'gross_weight': res.data.PesoBruto * 1000,
          'dry_weight': res.data.PesoNeto * 1000,
          'payload': res.data.PesoUtil * 1000,
        });
      }

      setPlateLoading(false);

    }
  }

  const changeWeight = (e) => {
    const gross = Number(form.getFieldValue('gross_weight'));
    const dry = Number(form.getFieldValue('dry_weight'));
    form.setFieldsValue({ payload: gross - dry });
  }

    return <div>


      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={mode === 'create' ? "Nuevo ingreso" : data?.certification?.name}
        subTitle={data?.date ? moment(data?.date).format('LLL') : ''}
        extra={[
          mode === 'edit' && <Button onClick={removeModel}  danger key="cancel">Anular</Button>,
          mode === 'edit' && <Button key="modifications"><Link to={'/certifications/resolve/'+id }>Modificaciones</Link></Button>,
          <Button key="save" type="primary" onClick={()=>form.submit()}>
            Guardar
          </Button>,
        ]}
      />

     

      <Form size="small" 
       labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }} form={form} name="control-hooks" 
        initialValues={{ certification_type_id: 20, plate: 'B3G019', transport_type_id : 1}} 
        onFinish={finish} >

        <Card loading={isFetching} className="cardex" title={<div style={{ display: 'flex', justifyContent:'space-between' }}><div>Datos básicos</div> {mode ==='edit' && <div style={{fontWeight: 300}}>Operación: {data?.operation_code}</div>}</div>} >


          <Form.Item labelCol={{ span: 3 }} name="certification_type_id" label="Tipo de cert." rules={[{ required: true }]}>
            <SelectAjax size="middle"  style={{ maxWidth: 400 }} url={'/certification-types?category_id=3'} />
          </Form.Item>

          <Form.Item labelCol={{ span: 2 }} name="company_id" label="Empresa" rules={[{ required: true }]}>
            <SelectAjax size="middle" showSearch optionFilterProp="children" url={'/companies-list'} style={{ maxWidth: 400 }}  />
          </Form.Item>

          <Form.Item labelCol={{ span: 2 }} name="garage_id" label="Taller" rules={[{ required: true }]}>
            <SelectAjax size="middle" showSearch optionFilterProp="children" style={{ maxWidth: 400 }} url={'/garages-list'} />
          </Form.Item>
        
         

          <Form.Item initialValue={'118-2021-10-012468-1'} labelCol={{ span: 2 }} name="plate" label="Placa" rules={[{ message: 'Ingresa placa válida', required: true, pattern: /^[0-9a-zA-Z\-]{6,50}$/ }]}>
            <Search loading={plateLoading} onSearch={searchPlate} placeholder="B3G019" style={{ width: 200 }} />
          </Form.Item>


          <Form.Item initialValue={'118-2021-10-012468-1'} labelCol={{ span: 2 }} name="dua" label="DUA" rules={[{ message: 'Ingresa dua válida', required: false, }]}>
            <Search loading={duaLoading} onSearch={searchDua} placeholder="Ingresa DUA" style={{ width: 250 }} />
          </Form.Item>

          


        
      </Card>


        <Card loading={isFetching} className="cardex" title="Datos del vehículo" style={{marginTop: 20}}>

          <Form.Item name="owner1"  labelCol={{ span: 2 }} label="Titular" rules={[{ required: true }]}>
            <Input size="middle" />
          </Form.Item>


          <Row>


            <Col span={15}>

              <Row>

                <Col span={10}>

                  <Form.Item name="class" label="Cat./Clase" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="model" label="Modelo" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="brand" label="Marca" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="serie" label="Serie" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="chassis" label="Chasis" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="motor" label="Motor" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="color" label="Color" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>





                  <Form.Item name="bodywork" label="Carrocería" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>


                  <Form.Item name="fuel" label="Combustible" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="power" label="Potencia" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  </Col>

                <Col span={14}>

                  <Row>
                    <Col span={12}>
                      <Form.Item name="year_fabric" label="Año Fabric" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="year_model" label="Año Model" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                    
                      <Form.Item name="axes_number" label="Ejes" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="wheels_number" label="Ruedas" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="seats_number" label="Asientos" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="engine_size" label="Cilindrada" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="longitude" label="Longitud" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                    </Col>

                    <Col span={12}>

                      <Form.Item name="height" label="Alto" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>


                      <Form.Item name="width" label="Ancho" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>



                      <Form.Item name="passenger_number" label="Pasajeros" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="cylinder_number" label="Cilindros" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item name="gross_weight" label="Peso Bruto" rules={[{ required: false }]}>
                      <Input onChange={changeWeight} />
                      </Form.Item>

                      <Form.Item name="dry_weight" label="Peso seco" rules={[{ required: false }]}>
                      <Input onChange={changeWeight} />
                      </Form.Item>

                      <Form.Item name="payload" label="Carga útil" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>


                      <Form.Item name="version" label="Version" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>

                    </Col>
              </Row>
                  </Col>

              </Row>

              

            </Col>

          </Row>

          <p style={{fontSize: 11, fontStyle: 'italic', textAlign: 'right'}}>* Los pesos son en Kilogramos (Kg)</p>

        </Card>

  
      
</Form>
          

      <CardInfo certification={data} />
    </div>
}

export default GNVCreate;