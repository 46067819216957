import axios from "axios";
import apiClient from "./api";

const API_URL = "http://montgall.test/api/auth";

const register = (username, email, password) => {
    return axios.post(API_URL + "/signup", {
        username,
        email,
        password,
    });
};

const login = (username, password) => {

    return apiClient.get('/sanctum/csrf-cookie')
        .then(response => {
            apiClient.post('/login', {
                email: username,
                password: password
            }).then(response => {
                
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;

            })
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default {
    register,
    login,
    logout,
    getCurrentUser,
};