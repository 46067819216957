import axios from 'axios';
import Config from '../Helpers/Config';

const apiClient = axios.create({
    baseURL: Config.baseUrl,
    withCredentials: true,
});

export const fetchGeneric = (url, params) => {
    return apiClient.get(url, {params}).then(res => res.data);
} 



export default apiClient;