
import apiClient from "./api";

const getModification = (certificationId) => {

    return apiClient.get("/certifications/" + certificationId + '/modifications').then(res => res.data);

}

const postGLP = (certificationId, params)=> {

    return apiClient.post("/certifications/" + certificationId + '/modifications/glp', params).then(res => res.data);

}




export default  {
    getModification, 
    postGLP, 
}
