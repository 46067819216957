
import apiClient from "./api";

const fetch = (page = 0) => {
    return apiClient.get( "/admin/companies?page=" + page, ).then(res => res.data);
};

const all = (page = 0) => {
    return apiClient.get( "/companies-list").then(res => res.data);
};


const find = (id) => {
    return apiClient.get("/admin/companies/"+id).then(res => res.data);
};

const remove = (id) => {
    return apiClient.delete("/admin/companies/"+id).then(res => res.data);
};

 const create = (params) => {
    return apiClient.post("/admin/companies", params).then(res => res.data);
};


 const update = (id, params) => {
    return apiClient.patch("/admin/companies/"+id, params).then(res => res.data);
};

 const prices = (id) => {
    return apiClient.get("/admin/companies/"+id + '/prices').then(res => res.data);
}; 

const updatePrices = (id, prices) => {
    return apiClient.post("/admin/companies/" + id + '/prices', prices).then(res => res.data);
};

export default  {
    fetch, 
    find, 
    create, 
    update, 
    remove, 
    all, 
    prices, 
    updatePrices, 
}
