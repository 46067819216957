
import apiClient from "./api";

const fetch = (page = 0) => {
    return apiClient.get( "/certifications?page=" + page).then(res => res.data);
};

const find = (id) => {
    return apiClient.get("/certifications/"+id).then(res => res.data.data);
};

const remove = (id) => {
    return apiClient.delete("/certifications/"+id).then(res => res.data);
};

 const create = (params) => {
     return apiClient.post("/certifications", params).then(res => res.data);
};

 const update = (id, params) => {
     return apiClient.patch("/certifications/"+id, params).then(res => res.data);
};

const updateModifications = (id, params)=> {

    return apiClient.post("/certifications/" + id + '/modifications', params).then(res => res.data);

}

const certificate = (certificationId, params) => {

    return apiClient.post("/certifications/" + certificationId + '/certificate').then(res => res.data);
}

const attachments = (certificationId, params) => {

    return apiClient.get("/certifications/" + certificationId + '/attachments', {params}).then(res => res.data);
}

const downloadPDF = (certificationId) => {

    return apiClient.get("/certifications/" + certificationId + '/pdf', { responseType: 'arraybuffer' }).then(res => res.data);
}

const exportExcel = (filters) => {

    return apiClient.get("/certifications/export-excel", { responseType: 'arraybuffer' , params: filters }).then(res => res.data);
}

export default  {
    fetch, 
    find, 
    create, 
    update, 
    remove, 
    updateModifications, 
    certificate, 
    downloadPDF, 
    attachments, 
    exportExcel, 
}
