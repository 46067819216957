import React, { useState } from "react";
import { DocumentTypes } from "../../../Helpers/Constants";
import CustomTable from "../../ui/CustomTable";
import { EditOutlined } from '@ant-design/icons';
import GarageForm from "./GarageForm";

const Garages = () => {
    const [currentId, setCurrentId] = useState(null);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        }, {
            title: 'Name',
            dataIndex: 'fullname',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Documento',
            dataIndex: 'document_number',
            key: 'document_number',
            render: (value, record) => DocumentTypes[record.document_type] + ": " + record.document_number,
            sorter: true,
        },
        {
            title: 'Direccion',
            dataIndex: 'address',
            key: 'address',
        },

        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'edit',
            render: (id) => <a onClick={() => setCurrentId(id)}><EditOutlined /></a>,
        },
    ];

    return (
        <div className="container">


            <CustomTable
                createForm={GarageForm}
                editForm={GarageForm}
                edit={currentId}
                title="Talleres"
                columns={columns}
                onCancel={() => setCurrentId(null)}
                url={'/admin/garages'} />

        </div>
    );
};

export default Garages;
