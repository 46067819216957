/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { message, Upload } from "antd";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Config from "../../Helpers/Config";
import apiClient from "../../services/api";
import certificationService from "../../services/certification.service";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const Uploader = ({ certificationId, type }) => {
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState(null);
  const [files, setFiles] = useState([]);

  const fetch = async (id) => {
    setFiles([]);
    const res = await certificationService.attachments(id, { type });

    const dt = res.data?.map((a) => {
      a.status = "done";
      a.uid = a.id;
      a.thumbUrl = a.url;
      return a;
    });

    setFiles(dt);
  };

  useEffect(() => {
    setID(certificationId);

    if (certificationId > 0) {
      fetch(certificationId);
    }
  }, [certificationId]);

  const handleChange = ({ fileList, file }) => {
    console.log("STATUS", file.status);
    if (file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (file.status === "done") {
      setLoading(false);
      fetch(ID);
    }
    if (file.status !== "done") {
      setFiles([...fileList]);
    }
  };

  const onRemove = async (file) => {
    console.log(file);

    try {
      const res = await apiClient.delete("/attachments/" + file.id);
      message.success(file.name + " " + res.data.message);
    } catch (error) {
      message.error(error.message);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginBottom: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload
      multiple
      name="file[]"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={true}
      fileList={files}
      withCredentials={true}
      action={`${Config.baseUrl}/certifications/${ID}/upload?type=${type}`}
      onRemove={onRemove}
      onChange={handleChange}
    >
      {files.length >= 8 ? null : uploadButton}
    </Upload>
  );
};

export default Uploader;
