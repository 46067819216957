import { message, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Input, Button, Select } from "antd";
import {
  DOCUMENT_TYPE_CE,
  DOCUMENT_TYPE_DNI,
  DOCUMENT_TYPE_RUC,
} from "../../../Helpers/Constants";
import customerService from "../../../services/customer.service";
import apiClient from "../../../services/api";

const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const CustomerForm = ({
  method,
  onCancel,
  onSuccess,
  title,
  visible,
  edit,
}) => {
  const [isVisible, setIsvisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setIsvisible(visible);
  }, [visible]);

  const fetch = async (id) => {
    const res = await customerService.find(id);
    form.setFieldsValue(res.data);
  };
  useEffect(() => {
    if (edit) {
      setCurrentId(edit);
      fetch(edit);
    }
  }, [edit]);

  const onGenderChange = (value) => {};

  const searchDniRuc = async (e) => {
    const text = e.target.value.replace(/[^0-9+]/g, "");
    try {
      let res;
      const documentType =
        form.getFieldValue("document_type") == 6 ? "ruc" : "dni";
      if (documentType === "dni" && text.length == 8) {
        setSearchLoading(true);
        res = await apiClient.get("/dni-ruc-search", {
          params: { type: documentType, document: text },
        });

        form.setFieldsValue({
          name: res.data.name,
          first_name: res.data.fathers_lastname,
          last_name: res.data.mothers_lastname,
          address: res.data.address,
        });
      } else if (text.length == 11) {
        setSearchLoading(true);
        res = await apiClient.get("/dni-ruc-search", {
          params: { type: documentType, document: text },
        });

        form.setFieldsValue({
          name: res.data.name,
          address: res.data.address,
        });
      }
    } catch (error) {}

    setSearchLoading(false);
  };

  const onFinish = async (values) => {
    let res;

    setLoading(true);
    try {
      if (method === "POST") {
        res = await customerService.create(values);
      }

      if (method === "PATCH") {
        res = await customerService.update(currentId, values);
      }

      if (!res.success) {
        message.error(res.message ?? "Error al guardar registro");
        return;
      }

      message.success(res.message);

      if (onCancel) onCancel();
      if (onSuccess) onSuccess(res.data);

      form.resetFields();
    } catch (error) {
      console.log(error);
      message.error(
        error?.response.data?.message ?? "Error al guardar registro"
      );
    }

    setLoading(false);
  };

  const cancelNow = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const removeModel = async () => {
    Modal.confirm({
      title: "Confirmar borrado",
      icon: <ExclamationCircleOutlined />,
      content: "Se borrará este registro y los registros relacionados.",
      okText: "SI",
      cancelText: "NO",
      onOk: async () => {
        setLoading(true);
        const res = await customerService.remove(currentId);
        setLoading(false);

        if (!res.success) {
          message.error(res.message ?? "Error al eliminar registro");
        }
        message.success(res.message ?? "Borrado exitoso");
        onSuccess();
        onCancel();
      },
    });
  };

  return (
    <Modal
      title={title}
      keyboard
      onOk={() => form.submit()}
      visible={isVisible}
      onCancel={() => cancelNow()}
      footer={[
        method === "PATCH" && (
          <Button type="link" danger key="link" onClick={removeModel}>
            Eliminar registro
          </Button>
        ),
        <Button key="back" onClick={cancelNow}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          form="form-create"
          type="primary"
          loading={loading}
        >
          Guardar
        </Button>,
      ]}
    >
      <Form
        {...layout}
        initialValues={{ document_type: DOCUMENT_TYPE_RUC }}
        form={form}
        name="form-create"
        onFinish={onFinish}
      >
        <Form.Item
          name="document_type"
          label="Tipo de doc."
          rules={[{ required: true }]}
        >
          <Select
            style={{ width: 200 }}
            placeholder="Selecciona"
            onChange={onGenderChange}
            allowClear
          >
            <Option value={DOCUMENT_TYPE_RUC}>RUC</Option>
            <Option value={DOCUMENT_TYPE_DNI}>DNI</Option>
            <Option value={DOCUMENT_TYPE_CE}>CE</Option>
          </Select>
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 24 }}
          name="document_number"
          label="N.documento"
          rules={[
            {
              required: true,
              message: "Ingresa un documento válido",
              pattern: /^[0-9]{3,30}$/,
            },
          ]}
        >
          <Input
            autoCorrect="false"
            autoComplete="off"
            suffix={<Spin size="small" spinning={searchLoading}></Spin>}
            onKeyUp={searchDniRuc}
          />
        </Form.Item>

        <Form.Item
          name="name"
          label="Nombre o RS"
          rules={[
            {
              required: true,
              message: "Nombre es obligatorio",
              type: "string",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.document_type !== currentValues.document_type
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("document_type") !== DOCUMENT_TYPE_RUC ? (
              <>
                <Form.Item
                  name="first_name"
                  label="Apellido Paterno"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="last_name"
                  label="Apellido Materno"
                  rules={[{ required: true, type: "string" }]}
                >
                  <Input />
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>

        <Form.Item
          name="address"
          label="Dirección"
          rules={[{ required: false, type: "string" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: false, type: "email" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomerForm;
