import React, { useState } from "react";

import CustomTable from "../../ui/CustomTable";

import { EditOutlined} from '@ant-design/icons';
import UserForm from "./UserForm";

const Users = () => {

    const [currentId, setCurrentId] = useState(null);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'eimai',
        }, 
        {
            title: 'Rol',
            dataIndex: 'roles',
            key: 'rol',
            render: (roles, record) =>  roles?.length > 0 ? roles.join(',') :   '-' ,
        },

        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'edit',
            render: (id) => <a onClick={() => setCurrentId(id)}><EditOutlined /></a>,
        },
    ];

    return (
        <div className="container">

            <CustomTable
                createForm={UserForm}
                editForm={UserForm}
                edit={currentId}
                title="Usuarios del sistema"
                columns={columns}
                onCancel={() => setCurrentId(null)}
                url={'/admin/users'} />

        </div>
    );
};

export default Users;
