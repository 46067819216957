import { Spin } from "antd";
import React from "react"

const FullLoader = ({message}) => {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Spin size="large" />
        <br />
        <span style={{ color: '#1DA57A', fontWeight: 'bold' }}>{message??'Cargando...'}</span>
    </div>
}

export default FullLoader;