import { Divider, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";


import { Form, Input, Button, Select } from 'antd';

import userService from "../../../services/user.service";
import companyService from "../../../services/company.service";

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};



const UserForm = ({ method, onCancel, onSuccess, title, visible, edit }) => {

    const [isVisible, setIsvisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentId, setCurrentId] = useState(false);

    const [form] = Form.useForm();

    const [companies, setCompanies] = useState([])



    useEffect(() => {
        setIsvisible(visible)
    }, [visible])

    const fetch = async (id) => {

       const res = await userService.find(id)
      const role = res.data.roles.length > 0 ? res.data.roles[0].name : null;
      form.setFieldsValue({ ...res.data, 
            role: role, 
            companies: res.data.companies.map(a => a.id)})
    }

    const fetchCompanies = async (id) => {

        const data = await companyService.all();

        setCompanies(data);
    }


    useEffect(() => {
        fetchCompanies();
        
        if (edit) {
            setCurrentId(edit) 
            fetch(edit);
        }
    }, [edit])

    const onFinish = async (values) => {


        let res;

        setLoading(true);
        try {

            if (method === 'POST') {
                res = await userService.create(values)
            }

            if (method === 'PATCH') {
                res = await userService.update(currentId, values)
            }


            if (!res.success) {
                message.error(res.message ?? 'Error al guardar registro');
                return;
            }

            message.success(res.message);
            
            if (onCancel)
                onCancel();
            if (onSuccess)
                onSuccess(res.data);

            form.resetFields();

        } catch (error) {
            console.log(error);
            message.error(error?.response.data?.message ?? 'Error al guardar registro');
        }

        setLoading(false);

    };


    const cancelNow = () => {

        if (onCancel) {
            onCancel();
        }
    }

    const removeModel = async () => {


        Modal.confirm({
            title: 'Confirmar borrado',
            icon: <ExclamationCircleOutlined />,
            content: 'Se borrará este registro y los registros relacionados.',
            okText: 'SI',
            cancelText: 'NO',
            onOk:  async ()=> {

                setLoading(true);

                const res = await userService.remove(currentId)
                setLoading(false);

                if (!res.success) {
                    message.error(res.message ?? 'Error al eliminar registro');
                }

                message.success(res.message??'Borrado exitoso');

                onSuccess();
                onCancel();
               
            }
        });
      
    }

    const handleChangeCompanies  = (items)=> {
        console.log(items)
    }

    const children = companies.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)

    return (
        <Modal title={title} keyboard onOk={() => form.submit()} visible={isVisible} onCancel={() => cancelNow()}

            footer={[
                method === 'PATCH' && <Button type="link" danger key="link" onClick={removeModel}>
                    Eliminar registro
                </Button>,
                <Button key="back" onClick={cancelNow}>
                    Cancelar
                </Button>,
                <Button key="submit" htmlType="submit" form="form-create" type="primary" loading={loading}>
                    Guardar
                </Button>
            ]}

        >

            <Form {...layout}  form={form} name="form-create" onFinish={onFinish}>


                <Form.Item name="role" label="Rol" rules={[{ required: true }]}>
                    <Select 
                        placeholder="Selecciona"
                        allowClear
                    >
                        <Option value={'superadmin'}>Superadmin</Option>
                        <Option value={'admin'}>Admin</Option>
                        <Option value={'technical'}>Técnico</Option>
                        <Option value={'secretary'}>Secretaria</Option>
                    </Select>
                </Form.Item>

                <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Nombre es obligatorio' ,}]}>
                    <Input />
                </Form.Item>

                <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>


                <Form.Item name="password" label="Contraseña" rules={[{ required: false,  }]}>
                    <Input.Password />
                </Form.Item>

                <Divider/>

                <Form.Item name="companies" label="Empresas" rules={[{ required: false }]}>
                <Select
                        optionFilterProp="children"
                    mode="multiple"
                    allowClear
                        listHeight={180}
                    style={{ width: '100%' }}
                    placeholder="Please select"
             
                >
                    {children}
                </Select>

                </Form.Item>


            </Form>
        </Modal>
    );
};

export default UserForm;
