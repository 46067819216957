/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { DocumentTypes, DOCUMENT_TYPE_RUC } from "../../../Helpers/Constants";
import CustomTable from "../../ui/CustomTable";
import CompanyForm from "./CompanyForm";
import { EditOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";


const Companies = () => {

    const [currentId, setCurrentId] = useState(null);

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            sorter: true,
            defaultSortOrder: 'descend',
        }, {
            title: 'Name',
            dataIndex: 'fullname',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Documento',
            dataIndex: 'document_number',
            key: 'document_number',
            render: (value, record) => DocumentTypes[record.document_type] + ": " + record.document_number,
            sorter: true,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },

        {
            title: 'Precios',
            dataIndex: 'id',
            key: 'edit',
            render: (id) => <Link to={window.location.pathname +'/'+ id + '/prices'}>Precios</Link>,
        },

        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'edit',
            render: (id) => <a title="Editar" onClick={() =>  setCurrentId(id)}><EditOutlined /></a>,
        },

    
    ];

return (
    <div className="container">

        <CustomTable 
        createForm={CompanyForm} 
        editForm={CompanyForm} 
        edit={currentId}
        title="Empresas" 
        columns={columns} 
        onCancel={()=> setCurrentId(null)}
        url={'/admin/companies'} />

    </div>
);
};

export default Companies;
