import React, { useState } from 'react';
import { Line } from '@ant-design/plots';

const CertificatesLine = () => {
  const [data, setData] = useState([
  {
    "year": "Enero",
    "value": 0,
    "category": "GLP"
  },
  {
    "year": "Enero",
    "value": 54,
    "category": "GNV"
  },
  {
    "year": "Enero",
    "value": 0,
    "category": "Talleres"
  },


  {
    "year": "Febrero",
    "value": 20,
    "category": "GLP"
  },
  {
    "year": "Febrero",
    "value": 78,
    "category": "GNV"
  },
  {
    "year": "Febrero",
    "value": 43,
    "category": "Talleres"
  },

 {
    "year": "Marzo",
    "value": 30,
    "category": "GLP"
  },
  {
    "year": "Marzo",
    "value": 71,
    "category": "GNV"
  },
  {
    "year": "Marzo",
    "value": 50,
    "category": "Talleres"
  },

   {
    "year": "Abril",
    "value": 30,
    "category": "GLP"
  },
  {
    "year": "Abril",
    "value": 39,
    "category": "GNV"
  },
  {
    "year": "Abril",
    "value": 34,
    "category": "Talleres"
  },

  {
    "year": "Mayo",
    "value": 80,
    "category": "GLP"
  },
  {
    "year": "Mayo",
    "value": 90,
    "category": "GNV"
  },
  {
    "year": "Mayo",
    "value": 90,
    "category": "Talleres"
  },
  
]);

 
  const config = {
   // theme: 'dark', 
    smooth: true, 
    data,
    xField: 'year',
    yField: 'value',
    seriesField: 'category',
    
  };

  return <Line {...config} />;
};

export default CertificatesLine;
